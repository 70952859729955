import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'

const BookPage = ({data}) => {
  return (
    <Layout>
        <h1>
            {data.wpBook.acfbook.bookTitle}
        </h1>
    </Layout>

  )
}

export default BookPage





export const query = graphql`
query($slug: String!) {
    wpBook(slug: { eq: $slug}) {
      acfbook {
        bookTitle
        isbn10
        isbn13
        bookImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`

